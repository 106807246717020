import { useContext } from 'react'
import { GlobalContext } from '../context/GlobalState'
import { numberWithCommas } from '../utils/format'

export const Balance = ({amount}) => {
  const { transactions } = useContext(GlobalContext)
  const amounts = transactions.map(transaction => transaction.amount)
  const total = amounts.reduce((acc, item) => acc + item, 0).toFixed(2)

  return (
    <>
      <h3>Your balance</h3>
      <div className="balance">${numberWithCommas(total)}</div>
    </>
  )
}
