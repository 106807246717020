import { useState, useContext } from "react"
import { GlobalContext } from "../context/GlobalState";

export const AddTransaction = () => {
  const { addTransaction } = useContext(GlobalContext)

  const [text, setText] = useState('');
  const [amount, setAmount] = useState(0);

  const onSubmit = e => {
    e.preventDefault();

    const newTransaction = {
      id: Math.floor(Math.random() * 100000) + 1,
      text,
      amount: +amount
    }

    addTransaction(newTransaction);
  }

  return (
    <div className="add-transaction">
        <h3>Add New Transaction</h3>
        <form id="form" onSubmit={onSubmit}>
            <div className="form-control">
                <label htmlFor="text">Text</label>
                <input type="text" value={text} onChange={(e) => setText(e.target.value)} id="text" placeholder="Enter text..."></input>
            </div>
            <div className="form-control">
                <label htmlFor="amount">Number</label>
                <input type="text" value={amount} onChange={(e) => setAmount(e.target.value)} id="amount" placeholder="Enter amount..."></input>
            </div>
            <button type="submit" className="btn">Add Transaction</button>
        </form>
    </div>
  )
}
