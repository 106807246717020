import { useContext } from "react"
import { GlobalContext } from "../context/GlobalState"
import { numberWithCommas } from '../utils/format'

export const IncomeExpenses = () => {
  const { transactions } = useContext(GlobalContext)

  const income = transactions.filter(transaction => transaction.amount > 0)
    .map(item => item.amount)
    .reduce((acc, item) => acc += item, 0).toFixed(2)

  const expense = transactions.filter(transaction => transaction.amount < 0)
    .map((item) => item.amount)
    .reduce((acc, item) => acc += Math.abs(item), 0).toFixed(2)

  return (
    <div className="inc-exp-container">
        <div>
            <h2>Income</h2>
            <p id="money-plus" className="money plus">${numberWithCommas(income)}</p>
        </div>
        <div>
            <h2>Expens</h2>
            <p id="money-minus" className="money minus">${numberWithCommas(expense)}</p>
        </div>
    </div>
  )
}
